<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <div class="d-inline-flex" style="width: 100%">
        <v-autocomplete
          :key="`${step.id}-processSettingName`"
          v-model="properties.processSettingName"
          outlined
          dense
          :items="settings"
          :loading="isLoadingProcessSetting"
          :search-input.sync="searchRequestProcessSetting"
          clearable
          hide-no-data
          item-text="name"
          item-value="name"
          :label="$lang.labels.processSettingName"
          :placeholder="$lang.actions.startTyping"
          prepend-inner-icon="mdi-cloud-search-outline"
          :readonly="!canEdit"
          data-cy="processSettings-credentialName"
          class="required-asterisk"
        />
        <v-btn
          icon
          light
          color="primary"
          class="ml-1"
          @click="searchProcessesSettings(searchProcessSetting)"
        >
          <v-icon
            dense
            small
          >
            mdi-refresh
          </v-icon>
        </v-btn>
        <v-btn
          text
          class="ml-1"
          color="primary"
          :disabled="!properties.processSettingName"
          @click="openProcessSetting()"
        >
          {{ $lang.actions.openTemplate }}
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        data-cy="processSettings-targetObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  getSettingsUsingGET as getSettings
} from '@/utils/api'
import StepMixin from './StepMixin'

export default {
  name: 'ProcessSettingStep',
  mixins: [StepMixin],
  data() {
    return {
      searchRequestProcessSetting: '',
      settings: [],
      isLoadingProcessSetting: false
    }
  },
  watch: {
    searchRequestProcessSetting: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchProcessesSettings(val)
        }
      }
    }
  },
  created() {
    this.searchRequestProcessSetting = this.properties.processSettingName
  },
  methods: {
    searchProcessesSettings(val = '') {
      this.isLoadingProcessSetting = true

      const obj = { permissionType: 'USE' }

      if (val && val.length > 1) obj.name = val

      obj.type = 'PROCESS'

      getSettings(obj)
        .then((res) => {
          this.settings = res.data.data.items
          this.isLoadingProcessSetting = false
        })
        .catch((error) => {
          this.isLoadingProcessSetting = false
          console.log(error)
        })
    }
  }
}
</script>
